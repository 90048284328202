<template>
  <div class="m-article">
    <div class="body">
      <div class="m-imageword-card">
        <div class="image" :style="{'background-image': `url('http://www.igg.cas.cn/gkjj/skjj/201811/W020201203402841454851.jpg')`}"></div>
        <div class="word">
          <h3 class="title">中国科学院地质与地球物理研究所所况简介</h3>
          <div class="summary">
            <p>中国科学院地质与地球物理研究所是1999年6月由中国科学院地质研究所和中国科学院地球物理研究所整合而成。2004年，中国科学院武汉物理与数学研究所电离层研究室整体调整到本所。整合后的地质与地球物理研究所是目前中国最重要和最知名的地学研究机构之一。</p>
            <p>研究所战略定位是“面向世界科技前沿，面向国家重大需求和国民经济主战场，以固体地球科学为主攻方向，建设‘理论创新＋技术研发＋成果转化+科教融合’四位一体的新型国际化研究中心”。在全球性地球科学问题研究上取得原创性突破，发展地球科学理论；聚焦国家资源、能源和环境领域重大科学问题，支撑国家战略需求和服务经济社会发展。</p>
          </div>
        </div>
      </div>
      <div class="word">
        <p>近年来，研究所致力于科研理念、组织架构、管理模式和创新体制等方面的转变。从全球视野的角度，组织科研力量对特提斯构造域演化与资源能源效应、克拉通破坏与巨量金属成矿、地球内部结构与过程、比较行星学等学科前沿问题开展了研究，并取得重要进展。同时聚焦国家资源探测战略需求，围绕深部资源探测先导技术和自主仪器装备研发展开集成攻关并取得关键突破。当前，研究所积极实施中国科学院“率先行动”计划，与中国科学院广州地球化学研究所和中国科学院地球化学研究所共同筹建“中国科学院地球科学研究院”，努力成为深部资源勘探装备与开发前沿技术的引领者，成为资源新理论与创新技术产业化的示范者和实践者，成为世界领先的深部资源技术解决方案提供者，成为高端地学人才培养者。</p>
        <p>研究所现有在岗人员780人，其中具有高级专业技术职务324人，中国科学院院士15人，中国工程院院士1人，国家杰出青年基金获得者38人，基础研究发展计划（973）首席科学家7人，国家自然科学基金委创新研究群体7个。2009年获中组部授予“海外高层次人才创新创业基地”，2014年获科技部授予“创新人才培养示范基地”。研究所是国家最早确定的硕士、博士研究生培养基地和博士后流动站单位，是中国科学院首批博士生重点培养基地，现有研究生699人（含留学生19人），博士后176人。</p>
        <p>研究所建有岩石圈演化国家重点实验室，以及地球与行星物理、页岩气与地质工程、矿产资源研究、油气资源研究、新生代地质与环境等五个中国科学院重点实验室和深部资源探测先导技术与装备研发中心。技术平台现有技术支撑人员173人，目前已建成地球物质成分与性质分析、地质年代学测定、地球内部结构探测、空间环境观测野外台站、古环境数据分析、数据计算处理与数值模拟、深部资源勘探装备研发等七大技术平台系列，具备了对从空间地磁环境观测到地球深部探测与数值模拟进行全方位综合研究的能力。牵头组建“中国科学院北京地球系统与环境科学大型仪器区域中心”，为研究所乃至国际上地球系统科学的研究提供强有力的技术支撑。</p>
        <p>近年来，研究所承担了国家科技重大专项、国家重大科研装备研制项目、国家重点研发计划、国家自然科学基金重大研究计划重点项目、重大项目课题、重点项目及中国科学院战略性先导专项、中国科学院重点部署项目等国家级重要项目200余项。研究所秉持唯才是举、宽松公平的学术文化理念，促进优秀人才成长，注重创新思想培育，着力提升开展大科学研究和解决国家重大需求的能力，在固体地球科学领域做出了一系列创新性成果。2000年（含）以来，以第一排名单位获得国家自然科学二等奖10项、国家科学技术进步二等奖1项，2003年刘东生院士获得国家最高科学技术奖。同时研究所高度重视知识产权工作，近五年（2013-2017）来获得国内外授权发明专利473项、实用新型或外观设计专利20项、登记软件著作权134个。</p>
        <!-- <p style="text-align: right;">（2018年11月）</p> -->
      </div>
      <p></p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.m-imageword-card {
  margin-bottom: 0;
  .image {
    height: 316px;
    width: 500px;
    border-radius: 0;
  }
  .word {
    padding: 20px 20px 0 32px;
    .title {
      font-size: 20px;
    }
    p {
      margin-bottom: 11px;
      line-height: 30px;
      text-indent: 2em;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.word {
  padding: 20px;
  p {
    margin-bottom: 11px;
    line-height: 30px;
    text-indent: 2em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
